import {StatusFilter} from '@wix/events-types'
import {EVENT_FILTER_TYPE, ExperimentNames} from '@wix/wix-events-commons-statics'
import type {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import settingsParams from '../../settingsParams'
import {getStatusFilter} from '../../utils/status-filter'
import type {State} from '../types/state'
import {getDynamicCategoryIds, isVeloOverrideUsed} from './velo-overrides'

export const getFilterTypeFromSettings = (state: State, flowAPI: ControllerFlowAPI) => {
  const filter = flowAPI.settings.get(settingsParams.filterType) as number

  if (
    isVeloOverrideUsed(state) &&
    (!flowAPI.experiments.enabled(ExperimentNames.CategoryIdVeloUpdate) || filter === EVENT_FILTER_TYPE.MANUAL)
  ) {
    return EVENT_FILTER_TYPE.UPCOMING_AND_PAST
  }

  return filter
}

export const getComponentStatusFilter = (state: State, flowAPI: ControllerFlowAPI) => {
  if (isVeloOverrideUsed(state) && !flowAPI.experiments.enabled(ExperimentNames.CategoryIdVeloUpdate)) {
    return StatusFilter.FUTURE_AND_PAST
  }

  const filterTypeFromSettings = flowAPI.settings.get(settingsParams.filterType) as number
  const filter: StatusFilter = getStatusFilter(filterTypeFromSettings)

  return filter
}

export const getComponentCategoryIds = (state: State, flowAPI: ControllerFlowAPI) =>
  getDynamicCategoryIds(state, flowAPI)

export const isManualFilterSelected = (flowAPI: ControllerFlowAPI) => {
  const filterTypeFromSettings = flowAPI.settings.get(settingsParams.filterType) as number

  return filterTypeFromSettings === EVENT_FILTER_TYPE.MANUAL
}
